import maxBy from 'lodash/maxBy';
import {ProductTag} from "@del-alto/data-types";

export function isHotSale() {
  const time = Date.now();
  return 1715523038388 < time && time <  1715828400000;
}

export enum PromotionQueryMode {
  BEST_INSTALLMENTS= 'BEST_INSTALLMENTS',
  BEST_MIX = 'BEST_MIX',
  BEST_PRICE = 'BEST_PRICE',
}

export interface PromotionAttributes {
  /** promotion discount. 0 if none */
  discount: number;

  /** promotion installments. 0 for cash only. */
  installments: number;
}

interface Promotion extends PromotionAttributes {
  /** Promotion applies to selected products. Not supported on group promotions. */
  products: ProductTag | null,

  /** minimum price for promotion to apply */
  from: number;

  /** minimum price is considered for the entire group */
  group: boolean;
}

const MIN_DISPLAYED_DISCOUNT = 0.1;
const MIN_DISPLAYED_DISCOUNT_TAG = 1; // don't display tags

const promotions: Promotion[] = [
  // 3 installments, from $30.000
  {
    products: null,
    from: 30_000,
    group: true,
    installments: 3,
    discount: 0,
  },
  // 10%, from $60.000 (cash)
  {
    products: null,
    from: 60_000,
    group: true,
    installments: 0,
    discount: 0.1,
  },

  // 6 installments, from $150.000
  {
    products: null,
    from: 150_000,
    group: true,
    installments: 6,
    discount: 0,
  },

  // sale, 20% off (cash only)
  {
    products: ProductTag.Sale,
    from: 0,
    group: false,
    installments: 0,
    discount: 0.2,
  },

  ...(isHotSale() ? [
    // hot sale, 10% off + 3 installments, from $10.000
    {
      products: null,
      from: 79_000,
      group: true,
      installments: 3,
      discount: 0.1,
    },

    {
      products: ProductTag.Sale,
      from: 0,
      group: false,
      installments: 3,
      discount: 0.2,
    },
  ] : [])
];

export const BEST_GROUP_DISCOUNT = promotions.find(promo => {
  return promo.group && !promo.products && promo.installments > 0 && promo.discount > 0;
}) || promotions.find(promo => {
  return promo.group && !promo.products && promo.discount > 0;
})

promotions.forEach(promo => {
  if (promo.group && promo.products) {
    console.error('Product tags are not supported in group discounts');
  }
});

// TODO - is there a case in which we ignore group promos?
function calculatePromotionAttributes(query: PromotionQueryMode, price: number, tags: ProductTag[] | null, totalPrice: number): PromotionAttributes | null {
  const applicablePromotions = promotions.filter(promo => {
    if (query !== PromotionQueryMode.BEST_PRICE && promo.installments === 0) {
      return false;
    }

    if (query !== PromotionQueryMode.BEST_INSTALLMENTS && promo.discount === 0) {
      return false;
    }

    if (promo.products && (!tags || !tags.includes(promo.products))) {
      return false;
    }

    return (promo.group ? totalPrice : price) >= promo.from;
  });

  return maxBy(
    applicablePromotions,
    query === PromotionQueryMode.BEST_INSTALLMENTS ?
      promo => promo.installments :
      promo => promo.discount
  ) || null;
}

export function getBestProductDiscount(individualPrice: number, tags: ProductTag[] | null, totalPrice: number, available: boolean) {
  if (!available) {
    return 0;
  }
  return calculatePromotionAttributes(PromotionQueryMode.BEST_PRICE, individualPrice, tags, totalPrice)?.discount || 0;
}

export function getBestPromoMix(individualPrice: number | null, tags: ProductTag[] | null, totalPrice: number, available: boolean): PromotionAttributes {
  const promo = (individualPrice && available) ? calculatePromotionAttributes(PromotionQueryMode.BEST_MIX, individualPrice, tags, totalPrice) : null;
  return promo || {
    discount: 0,
    installments: 0,
  };
}

export function getBestProductPrice(individualPrice: number, tags: ProductTag[] | null, totalPrice: number, available: boolean) {
  if (!available) {
    return individualPrice;
  }

  const discount = getBestProductDiscount(individualPrice, tags, totalPrice, available);
  return individualPrice * (1 - discount);
}

export function getBestMixPrice(individualPrice: number, tags: ProductTag[] | null, totalPrice: number, available: boolean) {
  const discount = available ? getBestPromoMix(individualPrice, tags, totalPrice, available).discount : 0;
  return individualPrice * (1 - discount);
}

export function getBestProductInstallments(individualPrice: number | null, tags: ProductTag[] | null, totalPrice: number, available: boolean) {
  if (!individualPrice || !available) {
    return 0;
  }

  return calculatePromotionAttributes(PromotionQueryMode.BEST_INSTALLMENTS, individualPrice, tags, totalPrice)?.installments || 0;
}

export function showDiscountPrice(discount: number) {
  return discount >= MIN_DISPLAYED_DISCOUNT;
}

export function showDiscountTag(price: number, tags: ProductTag[] | null, available: boolean) {
  const hotSale = isHotSale();
  const discount = getBestProductDiscount(price, tags, price, available);
  return hotSale && discount >= MIN_DISPLAYED_DISCOUNT_TAG;
}

export function isOnSale(tags: ProductTag[] | null) {
  const tagPool = tags || [];
  return tagPool.includes(ProductTag.Sale);
}
